<script setup lang="ts">
import { onMounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Package from './package.json'
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://f126836d05d43efaf109a03dc88e971b@o4507746368749568.ingest.de.sentry.io/4507764561412176",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // 0.1 = 10% of transactions
  tracePropagationTargets: [
    new RegExp(`^https://${rest.isStaging() ? 'staging.' : ''}customer\\.converso\\.io/api`),
  ],
  // Session Replay
  replaysSessionSampleRate: 0, // 0 = no session replay
  replaysOnErrorSampleRate: 0, // 0 = no session replay
  // Profiling
  profilesSampleRate: 1.0,
});

const route = useRoute()
const router = useRouter()
const store = usePortalStore()

rest.setDomainRoot()

router.afterEach(async (to) => {
  const { path, params, name } = to
  const stringRoute = JSON.stringify({ path, params, name })

  // when returning to the home screen, if the user is a guest and 
  // a current conversation ID has been stored, do not store the home screen URL
  // so we do not end up returning to it 
  await store.getStoredConversationId();

  if (
    name === 'widget-businessCode-group' &&
    await store.getConversationId &&
    store.getLoggedInUser.level > 0
  ) {
    return
  }

  store.SET_LAST_ROUTE(stringRoute)

  window.parent.postMessage({
    type: 'set_route',
    value: name
  }, '*')
})

onMounted(() => {
  store.storeAndCompareFingerprintWithCookie()
  
  if (!route.params.businessCode) {
    throw new Error('Business code missing in route - ABORTING!')
  }
  store.SET_BUSINESS_CODE(route.params.businessCode)

  if (route.params.group) {
    store.SET_GROUP(route.params.group)
  }

  window.addEventListener('message', (ev) => {
    handlePostMessage(ev, store, router, route)
  })

  // handle re-login when browser comes online
  window.addEventListener("online", () => {
    console.warn('ONLINE EVENT HANDLED')
    store.SET_IS_ONLINE(true)
    store.checkAuthToken()
  })
  window.addEventListener("offline", () => {
    console.warn('OFFINE EVENT HANDLED')
    store.SET_IS_ONLINE(false)
  })
  window.addEventListener("focus", () => {
    console.warn('FOCUS EVENT HANDLED')
    store.throttledCheckDataFreshness(true)
  })

  //listenerReady
  if (window.self !== window.top) {
    window.parent.postMessage({
      type: 'listenerReady'
    }, '*')

    window.parent.postMessage({
      type: 'set_route',
      value: route.name
    }, '*')
  }

  const routesWithNoLogin = [
    'validate-password',
    'validate-email',
    'validate-phone',
    'type-businessCode-invoiceCode'
  ]

  // no need to log in or connect websocket on these routes
  if (routesWithNoLogin.includes(route.name as string)) return

  store.checkAuthToken()

  nextTick(async () => {
    if (store.getLastRoute && route.name !== 'invoice-page') {
      const unescaped = store.getLastRoute.replace(/\\/g,"")
      const parsed = JSON.parse(unescaped)
      const currentRoute = route.name
      const storedConversationId = store.getConversationId
      if (
        route.path.includes('chat') &&
        !isDefined(storedConversationId)
      ) {
        // do not return to the previous route
        return
      }
      if (currentRoute !== parsed) {
        router.push(parsed)
        return
      }
    }
  })
})

const showSignin = computed({
  get: () => {
    return store.getShowSignin
  },
  set: val => {
    store.SET_SHOW_SIGNIN(val)
  }
})

</script>
<template>
  <NuxtLayout :class="$route.name" :version="`${Package.version}`">
    <online-status />
    <NuxtPage />
    <transition name="fade">
      <LoginPanel
        v-show="showSignin"
        ref="loginRef"
        @close="showSignin = false"
      />
    </transition>
  </NuxtLayout>
</template>
