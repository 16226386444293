import rest from '../utils/rest'
import { Business, Conversation, Invoice, Message, User, type FileEntity } from '../utils/DTOs'
import { useRouter, useRoute } from "vue-router"
import Package from '../package.json'
import * as Sentry from '@sentry/browser';
import { v4 as uuidv4 } from 'uuid';
import { throttle } from 'lodash-es';
import SharedWorker from "@okikio/sharedworker";
import localforage from 'localforage'
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import { getCookie, setCookie } from '~/utils/helpers';

function getRouteParams() {
  const routeParams = document.location.pathname.slice(1).split('/')
  // route params always end with /businessCode/groupId
  return routeParams
}
const routeParams = getRouteParams()
const storeName = `portalStore-${routeParams[routeParams.length-2]}-${routeParams[routeParams.length-1]}`

let loginRetries = 0
const maxLoginRetries = 10
const recoveryTimeoutDuration = 1000

const showTypingTO = ref<ReturnType<typeof setTimeout>>()
const hideTypingTO = ref<ReturnType<typeof setTimeout>>()
const closedEventTO = ref<ReturnType<typeof setTimeout>>()
const sendTokenEventTO = ref<null | ReturnType<typeof setTimeout>>(null)

const userFingerprint = ref<string>()

const requestingLogin = ref(false)

export const usePortalStore = defineStore(storeName, () => {
  const router = useRouter()
  const route = useRoute()
  const isOnline = ref(true)
  const showSignin = ref(false)
  const authToken = ref<null | string>(null)
  const isTyping = ref(false)
  const chatMessages = ref<null | readonly Message[]>()
  const users = ref<null | User[]>(null)
  const wasBootedOut = ref(false)
  const conversations = ref<null | readonly Conversation[]>()
  const chatUIState = ref(2) // 0: full_nav, 1: full_conversations, 2: full_message
  const conversation = ref<null | Conversation>(null)

  const getLocalStorageSuffix = computed(() => {
    const routeParams = getRouteParams()
    const localStorageSuffix = `_${routeParams[routeParams.length - 2]}-${routeParams[routeParams.length - 1]}`
    return localStorageSuffix
  })

  const conversationId = ref<null | undefined | number>(0)
  getStoredConversationId().then(id => conversationId.value = id)
  
  const group = ref<null | number>(null)
  const invoice = ref<null | Invoice>(null)
  const invoices = ref<null | Invoice[]>(null)
  const business = ref<null | Business>(null)
  const conversationLastReadTimes = ref<{[key: number]: number}>({})
  const businessCode = ref<null | string>(null)
  const invoiceCode = ref<null | string>(null)
  const loggedInUser = ref<null | User>(null)
  try {
      localforage.getItem<string>(`loggedInUser${getLocalStorageSuffix.value}`).then(storedLoggedInUser => {
      if (storedLoggedInUser) {
        const jsonLoggedInUser = JSON.parse(storedLoggedInUser) as User
        loggedInUser.value = jsonLoggedInUser
        authToken.value = jsonLoggedInUser.token
      }
    }).catch(err => {
      console.error(err)
    })
  } catch (err) {
    console.error(err)
  }

  const username = ref<null | string>(null)
  const wasAnonymous = ref(true)
  const widgetSettings = ref<null | {[key: string]: string | number}>(null)
  const defaultSettings = ref<null | {[key: string]: string | number}>(null)
  const isFullDataRefresh = ref(false)

  const lastRoute = ref<null | string>(null)
  try {
      localforage.getItem<string>(`lastRoute${getLocalStorageSuffix.value}`).then(response => {
      if (response) {
        lastRoute.value = response
      }
    }).catch(err => {
      console.error(err)
    })
  } catch (err) {
    console.error(err)
  }

  const messageSentConfirmationTO = ref<{
    to: ReturnType<typeof setTimeout> | null,
    text: string | null
  }>({
    to: null,
    text: null
  })
  const messageSentConfirmationDelay = 2000;
  const lastSync = ref<null | number>(null);
  const throttleDelay = 5000;
  const HEARTBEAT_INTERVAL = 10000
  const token = computed(() => loggedInUser.value?.token)
  const tabId = uuidv4();

  let webSocketWorker: SharedWorker | null = null
  let heartbeatTO: ReturnType<typeof setTimeout>
  let lastHeartbeat: number

  const throttledCheckDataFreshness = throttle(async (incrementalSync: boolean = false) => {
    if (isFullDataRefresh.value) {
      console.warn('Data refresh in progress, exiting...')
      return
    }

    if (!getAuthToken.value) {
      console.warn('not auth\'d yet, exiting...')
      return
    }

    if(isNaN(getUserLevel.value) || getUserLevel.value < 1) {
      console.warn('user level too low, exiting...')
      return;
    }

    SET_IS_FULL_DATA_REFRESH(true)
    
    try {
      if (getUserLevel.value > 1) {
        await fetchInvoices()
      }
      await fetchConversations(incrementalSync)
      await fetchMessages(incrementalSync)
      await fetchUsers(incrementalSync)
    } catch (err) {
      console.error(err)
    } finally {
      lastSync.value = Date.now();
      SET_IS_FULL_DATA_REFRESH(false)
    }
  }, throttleDelay);

  function waitForSendToken () {
    // should receive a CLOSED WS event immediately, if not, recover
    if (sendTokenEventTO.value === null) {
      console.warn('Started 5sec timeout to wait for sendToken: ', webSocketWorker)
      sendTokenEventTO.value = setTimeout(() => {
        // no closed event recieved - recover
        console.warn('sendToken not received - kill shared worker: ', webSocketWorker)
        killSharedWorker()
      }, 5000)
    } else {
      console.warn('sendTokenEventTO.value not NULL: ', sendTokenEventTO.value)
    }
  }

  function createSharedWorker() {
    console.log('createSharedWorker:::')
    if (
      webSocketWorker?.port?.postMessage &&
      loggedInUser.value
    ) {
      // sharedWorker already exists - reconnect socket
      webSocketWorker.port.postMessage({
        action: 'connect',
        value: JSON.stringify({ }),
        token: loggedInUser.value?.token, 
        tabId,
        businessCode: business?.value?.businessCode,
        userId: loggedInUser.value?.id,
      })

      // should receive a CLOSED WS event immediately, if not, recover
      waitForSendToken()
      return webSocketWorker
    }

    const sendMessageToSocket = (value: string, action: 'send' | 'initialise' | 'updateUserId'| 'unload' | 'sendToken' | 'kill' | 'connect' = 'send' )=> {
      webSocketWorker?.port.postMessage({
        action,
        value,
        token: token.value || 'NO TOKEN', 
        tabId,
        businessCode: businessCode.value,
        userId: loggedInUser.value?.id,
      });
    };
    
    // Create shared worker.
    const workerUrl = `/web-sockets-worker.js?ts=${Package.version}`;

    if (!SharedWorker && !window.SharedWorker) {
      throw new Error('SharedWorker not supported - exiting!')
    }

    if (webSocketWorker?.port) {
      // unload any existing socket before initialization
      sendMessageToSocket('','unload');
      throttledCheckDataFreshness(true);
    }

    webSocketWorker = new SharedWorker(workerUrl, {
      name: `converso-worker-${getLocalStorageSuffix.value}-${Package.version}`
    });

    // Event to listen for incoming data from the worker and update the DOM.
    webSocketWorker.port.addEventListener('message', async ({ data }) => {
      if (data === 'sendToken') {
        console.log('sendToken message received');
        // const token = token || 'NO TOKEN'
        if (sendTokenEventTO.value) {
          clearInterval(sendTokenEventTO.value);
          sendTokenEventTO.value = null
        }
        console.log('sending token: ', token.value);
        sendMessageToSocket(JSON.stringify({ type: 'token', value: token.value }), 'sendToken');
        return;
      }

      if (data === 'refreshToken') {
        console.log('opening connection failed - refresh the token and try again');
        checkAuthToken();
        return
      }

      if (data === 'kill') {
        console.log('killing serviceWorker');
        sendMessageToSocket('','kill');
        return
      }

      if (data === 'heartbeat') {
        console.log('Received heartbeat from sharedWorker');
        if (sendTokenEventTO.value) {
          clearInterval(sendTokenEventTO.value);
          sendTokenEventTO.value = null
        }
        lastHeartbeat = Date.now()
        if (heartbeatTO) {
          clearTimeout(heartbeatTO)
          heartbeatTO = setTimeout(() => {
            const heartStoppedMsg = `No heartbeat received since: ${lastHeartbeat} - it is now ${Date.now()}`
            Sentry?.captureMessage(heartStoppedMsg);
            console.warn(heartStoppedMsg)
            checkAuthToken();
          }, HEARTBEAT_INTERVAL * 3)
        }
        return
      }

      if (data.includes('socket-error:')) {
        Sentry?.captureMessage(`Websocket error: ${data}`);
        console.error(`Websocket error: ${data}`)
        return;
      }

      if (data.includes('socket-closed:')) {
        clearTimeout(closedEventTO.value)
        Sentry?.captureMessage(`Websocket closed: ${data}`);
        console.error(`Websocket closed: ${data}`)
        checkAuthToken();
        return;
      }

      if (data.includes('socket.readyState:')) {
        console.debug(data)
        return;
      }

      handleMessage(data);
    });

    // Initialize the port connection.
    if (typeof webSocketWorker.port.start === 'function') {
      webSocketWorker.port.start();
    }

    sendMessageToSocket(JSON.stringify({ }), 'initialise');

    // Remove the current worker port from the connected ports list.
    // This way your connectedPorts list stays true to the actual connected ports, 
    // as they array won't get automatically updated when a port is disconnected.
    window.addEventListener('beforeunload', () => {
      console.log('beforeunload');
      sendMessageToSocket('','unload');
    });

    waitForSendToken()
    return webSocketWorker
  }

  function killSharedWorker () {
    console.warn('websocket-actions: killSharedWorker')
    webSocketWorker?.port?.postMessage({
      action: 'kill',
      value: JSON.stringify({ }),
      token: loggedInUser.value?.token, 
      tabId,
      businessCode: business.value?.businessCode,
      userId: loggedInUser.value?.id,
    })

    // should receive a CLOSED WS event immediately, if not, recover
    SET_WEBSOCKET_CLOSE_TO();
  }

  async function fetchInvoice() {
    try {
      console.log('action:::getInvoice:', businessCode.value, invoiceCode.value)
      const url = `${rest.getAPI('getInvoices', 'payments')}${businessCode.value}/all/code/${invoiceCode.value}`
      const data = await $fetch<Invoice[]>(url)
      if (data) {
        SET_INVOICE(data[0])
        console.log('action:::getInvoice:::got:', data[0])
        return data[0]
      }
    } catch (e) {
      console.error(e)
    }
  }
  async function fetchBusiness() {
    const url = `${rest.getAPI('getBusiness', 'payments')}${businessCode.value}`
    try {
      const data = await $fetch<Business[]>(url)
      if (data) {
        if (data[0].logo) {
          const resourceId = +data[0].logo.slice(-1)[0]
          data[0].logoBase64 = await getFileURL(resourceId)
        }
        SET_BUSINESS(data[0])
      }
    } catch (err) {
      console.log(err)
    }
  }
  async function fetchUsers(incrementalSync: boolean = false) {
    console.log(':::fetchUsers:::')

    let url = `${rest.getAPI('getUsers', 'payments')}${businessCode.value}/`
      if (lastSync.value && incrementalSync) {
        url += `updated/${lastSync.value}`
      } else {
        url += 'id/all'
      }

      try {
        const updatedUsers = await $fetch<User[]>(url)
        if (!updatedUsers) {
          return;
        }
        updatedUsers.forEach(async user => {
          if (user.picture) {
            try {
              user.pictureBase64 = await getFileURL(user.picture)
            } catch (err) {
              if (user.level === 90) {
                user.pictureBase64 = '/ai.svg'
              } else {
                user.pictureBase64 = '/anonymous-user.svg'
              }
            }
          } else {
            if (user.level === 90) {
              user.pictureBase64 = '/ai.svg'
            } else {
              user.pictureBase64 = '/anonymous-user.svg'
            }
          }
          ADD_USER(user)
        })
      } catch (err) {
        console.error(err)
      }
  }

  async function fetchInvoices() {
    if (!getBusinessCode.value) {
      console.warn('getInvoices::: no business code')
      return
    }
    if (!getAuthToken.value) {
      console.warn('getInvoices::: no auth token')
      return
    }
    try {
      const url = `${rest.getAPI('getInvoices', 'payments')}${businessCode.value}/1/id/all`
      const data = await $fetch<Invoice[]>(url)
      if (data) {
        SET_INVOICES(data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  async function fetchConversations(incrementalSync: boolean = false) {
    if (!getBusinessCode.value) {
      console.warn('getConversations::: no business code')
      return
    }
    if (!getAuthToken.value) {
      console.warn('getConversations::: no auth token')
      return
    }
    try {
      let url = `${rest.getAPI('getConversations', 'payments')}${businessCode.value}/all/`
      if (lastSync.value && incrementalSync) {
        url += `updated/${lastSync.value}`
      } else {
        url += 'id/all'
      }

      const data = await $fetch<Array<Conversation>>(url)
      if (!data) {
        return;
      }

      switch (data.length) {
        case 0:
          break

        case 1:
          if (!incrementalSync) {
            SET_CONVERSATION(data[0])
            if (!conversations.value) {
              SET_CHAT_UI_STATE(2)
            }
          }
          break

        default:
          if (!getConversations.value) {
            SET_CHAT_UI_STATE(1)
          }
      }
      SET_CONVERSATIONS(data, incrementalSync);

      SET_IS_TYPING(false)
    } catch (e) {
      console.error(e)
    }
  }

  async function logout(skipRemoveLoginData?: boolean) {
    const url = rest.getAPI('logout', 'payments')
    await $fetch(url, {
      method: 'PUT',
      headers: {
        token: getAuthToken.value
      }
    })

    if (!skipRemoveLoginData) {
      await removeLoginData()
      anonymousLogin()
    }
  }

  async function removeLoginData() {
    console.warn('REMOVING STORED DATA!!')
    await localforage.removeItem(`user_level${getLocalStorageSuffix.value}`)
    await localforage.removeItem(`conversationId${getLocalStorageSuffix.value}`)
    SET_USERNAME('')
    SET_AUTH_TOKEN(null)
    SET_BUSINESS_CODE('')
    SET_LOGGED_IN_USER(null)
    SET_CONVERSATIONS([])
    SET_INVOICES([])
    SET_MESSAGES(null)
    SET_CHAT_UI_STATE(2)
  }

  async function fetchMessages(incrementalSync: boolean = false) {
    if (!businessCode.value) {
      console.warn('getMessages::: no business code')
      return
    }
    if (!getAuthToken.value) {
      console.warn('getMessages::: no auth token')
      return
    }
    try {
      let url = `${rest.getAPI('getMessages', 'payments')}${businessCode.value}/all/all/`
      if (lastSync.value && incrementalSync) {
        url += `updated/${lastSync.value}`
      } else {
        url += 'id/all'
      }
      const data = await $fetch<Message[]>(url)
      console.log('GOT MESSAGES: ', data)
      if (data) {
        SET_MESSAGES(data, incrementalSync)

        SET_IS_TYPING(false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  function selfService(args: {
    endpoint: 'email' | 'phone' | 'password',
    token: string,
    password: string
  }) {
    let apiName

    switch (args.endpoint) {
      case 'email':
        apiName = 'validateEmail'
        break

      case 'phone':
        apiName = 'validatePhone'
        break

      case 'password':
        apiName = 'updatePassword'
        break
    }

    try {
      const url = `${rest.getAPI(apiName, 'payments')}`
      const payload: {
        token: string,
        password?: string
      } = {
        token: args.token
      }

      if (args.endpoint === 'password') {
        payload.password = args.password
      }
      return useFetch(url, {
        method: 'POST',
        body: payload
      })
    } catch (e) {
      console.error(e)
      return e
    }
  }

  function getBankIntent() {
    const url = `${rest.getAPI('getBankIntent', 'payments')}${businessCode.value}/${invoiceCode}/bank`
    return useFetch(url)
  }

  function getCrezcoLink(args: {
    country: string,
    bank: {
      bankCode: string
    }
  }) {
    if (invoice.value) {
      const url = `${rest.getAPI('getCrezcoPaymentLink', 'clean')}${businessCode.value}/${invoice.value.id}/${args.country}/${args.bank.bankCode}`
      return useFetch(url)
    }
  }

  function getCrezcoBanks() {
    const url = `${rest.getAPI('getCrezcoBanks', 'clean')}GB`
    return useFetch(url)
  }

  function beaconTimeoutHandler(_, args: {mins: number}) {
    const { mins } = args
    console.warn(`no beacon received for ${mins} minutes - requesting new fb token`)

    if (window.self !== window.top) {
      // is in iframe
      const payload = {
        type: 'request',
        state: 'known-user'
      }
      window.parent.postMessage(payload, '*')
    }
  }

  async function updateImageFile(args: {fileDto: FileEntity, logoFile: Blob }) {
    let { fileDto } = args
    const { logoFile } = args
    if (fileDto.id === 0) {
      const { data } = await putFile(fileDto)
      console.log('file created: ', data)
      if (data.value) {
        fileDto = (data.value as FileEntity[])[0]
      }
    }
    const formData = new FormData()
    formData.append('file', logoFile)
    Object.keys(fileDto).forEach((key) => {
      if (key !== 'file') {
        formData.append(key, fileDto[key])
      }
    })

    // put resource
    const resource = await putResource({
      multipartPayload: formData,
      type: 'resource'
    })
    return resource
  }

  const getFileURL = async (fileId: number) => {
    const url = `${rest.getAPI('resource', 'clean')}${businessCode.value}/${fileId}?cb=${Date.now()}`
    const response = await $fetch<Blob>(url, {
      responseType: 'blob'
    })
    const reader = new window.FileReader();
    reader.readAsDataURL(response)
    return new Promise((resolve) => {
      reader.onload = function () {
        const imageDataUrl = reader.result
        resolve(imageDataUrl)
      }
    })
  }

  function putFile(jsonPayload: FileEntity) {
    console.log('Put File')
    const url = `${rest.getAPI('file', 'payments')}${businessCode.value}`
    return useFetch(url, jsonPayload)
  }

  function putResource(args: {multipartPayload: FormData, type: 'resource' | 'supportResource'}) {
    console.log(`Put ${args.type}`)
    const url = `${rest.getAPI(args.type, 'clean')}${businessCode.value}`
    return useFetch(url, {
      method: 'PUT',
      body: args.multipartPayload,
      params: {
        isMultipart: true
      }
    })
  }

  function constructNewConversation() {
    const newMessage = {}
    newMessage.id = 0
    newMessage.type = 0
    newMessage.created = 0
    newMessage.updated = 0
    newMessage.avatar = ''
    newMessage.status = '{"total": 0, "unsent": 0, "failed": 0, "sent": 0, "delivered": 0, "read": 0}'
    newMessage.text = 'Hi there, how can we help?'
    newMessage.sender = isDefined(loggedInUser.value?.id) ? loggedInUser.value?.id : 0
    newMessage.channel = 0
    newMessage.conversation = 0

    const newConversation = makeNewConversation()
    SET_CONVERSATION(newConversation)
    router.push(`/widget/chat/${route.params.businessCode}/${route.params.group}`)
  }

  function makeNewConversation() {
    const conversation = new Conversation()
    conversation.customers = loggedInUser.value ? [loggedInUser.value.id] : []
    conversation.assigned = []
    if (group.value) {
      conversation.group = group.value
    }
    conversation.type = 0
    return conversation
  }

  function createNewConversation() {
    return new Promise<Conversation>((resolve, reject) => {
      const url = `${rest.getAPI('getConversations', 'payments')}${businessCode.value}`
      $fetch<Conversation[]>(url, {
        method: 'PUT',
        body: JSON.stringify(makeNewConversation())
      })
        .then(data => {
          if (!data) {
            console.warn('createNewConversation::: no data')
            return
          }
          console.log('newConversation:', data[0])
          UPDATE_CONVERSATION(data[0])
          if (data.length > 0) {
            resolve(data[0])
          } else {
            reject(new Error('NO DATA'))
          }
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  }

  function updateConversation(conversation: Conversation) {
    const url = `${rest.getAPI('getConversations', 'payments')}${businessCode.value}`

    return new Promise((resolve, reject) => {
      $fetch<Conversation[]>(url, {
        method: 'PUT',
        body: conversation
      })
        .then(data => {
          if (data) {
            resolve(data[0])
          }
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  }

  async function getStoredConversationId() {
    const storedConversationId = await localforage.getItem<string>(`conversationId${getLocalStorageSuffix.value}`)
    if (storedConversationId !== null) {
      const parsedConversationId = parseInt(storedConversationId)
      conversationId.value = parsedConversationId
      return parsedConversationId
    }

    return null
  }

  async function anonymousLogin() {
    if (!businessCode.value) {
      console.warn('WE LOST THE BUSINESS CODE ;-(')
      return
    }

    SET_BUSINESS_CODE(businessCode.value)
    const username = `anonymous@${businessCode.value}`
    SET_USERNAME(username)
    await signIn(username)
    SET_USERNAME('')
  }

  async function checkAuthToken (authError?: boolean) {
    if (requestingLogin.value) {
      console.warn('Login already in progress - exiting')
      return
    }

    if (!loggedInUser.value?.token ) {
      requestLogin()
      return
    }
    
    try {
      const url = `${rest.getAPI('authPing', 'payments')}`
      await $fetch<string>(url, {
            method: 'PUT'
      })
      if (loggedInUser.value) {
        handleLoginResponse(loggedInUser.value)
        await throttledCheckDataFreshness()
      }
    } catch (err) {
      console.error(err)
      requestLogin(authError)
    }
  }

  async function requestLogin(authError?: boolean) {
    console.warn(`RequestLogin::: retrying ${loginRetries} of ${maxLoginRetries}`)
    
    if (requestingLogin.value) {
      console.warn('Login already in progress - exiting')
      return
    }

    requestingLogin.value = true
    SET_WAS_ANONYMOUS(getLoggedInUser.value?.name === 'Anonymous' && getLoggedInUser.value?.surname === 'Visitor')
    SET_WAS_BOOTED_OUT(true)
    const storedUserLevel = await localforage.getItem<number>(`user_level${getLocalStorageSuffix.value}`)
    const userLevel = typeof storedUserLevel === 'number' ? storedUserLevel : 0
    
    try{
      if (isNaN(userLevel)) {
        await anonymousLogin()
        loginRetries = 0
        return
      }

      switch (userLevel) {
        case 0:
          if (authError) {
            console.warn('Anonymous user (level 0) - login request coming after auth token check failed');
          }

          SET_LAST_ROUTE(JSON.stringify({ name: 'widget-businessCode-group' }))
          await anonymousLogin()
          loginRetries = 0
          break

        case 1:
          const login = await localforage.getItem<string>(`guest_email${getLocalStorageSuffix.value}`)
          const password = await localforage.getItem<string>(`guest_password${getLocalStorageSuffix.value}`)
          const storedConversationId = await getStoredConversationId()

          if (login && password) {
            await signIn(login, password)
            SET_SHOW_SIGNIN(false)
            loginRetries = 0
            await throttledCheckDataFreshness()

            if (isDefined(storedConversationId)) {
              SET_CONVERSATION_ID(storedConversationId)
            } else {
              if (getConversations.value) {
                switch (getConversations.value.length) {
                  case 1:
                    // go to that conversation
                    setConversation(getConversations.value[0])
                    break

                  default:
                    // go to the conversations list
                    goToConversationList()
                }
              } else {
                router.replace(`/widget/${route.params.businessCode}/${route.params.group}`)
              }
            }
          } else {
            console.warn(`No login (${login}) or password: ${password} from localStorage`)
            await removeLoginData()
          }
          break

        default:
          SET_SHOW_SIGNIN(true)
          await removeLoginData()
      }
    } catch (err) {
      console.error(err)
    } finally {
      requestingLogin.value = false
      console.log(`RequestLogin::: FINALLY Statement reached. requestingLogin.value = ${requestingLogin.value}`)
    }
  }
  async function sendMessage(message: Message) {
    // check if we are a VISITOR or GUEST
    /*
    - user level for visitors is 0
    - user level for guests is 1
    */
  
    if (getLoggedInUser.value && getLoggedInUser.value.level === 0) {
      // upgrade user to a visitor
      await upgradeVisitorToGuest()
    }

    if (message.conversation === 0) {
      // create conversation
      const newConversation = await createNewConversation()
      
      if (newConversation) {
        // send message
        message.conversation = newConversation.id
        SET_CONVERSATION(newConversation)
        sendMessage(message)
      }

      return
    }

    const url = `${rest.getAPI('putMessage', 'payments')}${businessCode.value}/${group.value}/${message.conversation}/${message.channel}/send`
    console.log('sendMessage: ', url)

    messageSentConfirmationTO.value.text = message.text
    messageSentConfirmationTO.value.to = setTimeout(() => {
      console.warn('No WS confirmation of this message, re-auth and reconnect websocket')
      messageSentConfirmationTO.value.text = null
      if (sendTokenEventTO.value) {
        clearInterval(sendTokenEventTO.value);
        sendTokenEventTO.value = null
      }
      checkAuthToken()
    }, messageSentConfirmationDelay)

    $fetch<Array<Message>>(url, {
      method: 'PUT',
      body: message
    })
      .then((response) => {
        console.log('sendMessage:', response)
        const newMsg: undefined | Message = response?.[0]
        if (!newMsg) {
          return
        }

        const existingConvo = conversations.value?.filter(convo => convo.id === newMsg.conversation)[0]

        if (!existingConvo) {
          fetchConversations(true)
        }

        // add the message to the conversation from the response
        ADD_CHAT_MESSAGE({newMessage: newMsg})

        // delay showing of typing dots
        showTypingTO.value = setTimeout(() => SET_IS_TYPING(true), 1000)
      })
      .catch((err) => {
        console.error(err)
      }).finally(() => {
        // hide typing dots after 50sec
        hideTypingTO.value = setTimeout(() => SET_IS_TYPING(false), 50000)
      })
  }
  async function upgradeVisitorToGuest() {
    const url = `${rest.getAPI('upgrade', 'payments')}`
    try {
      const loggedInUserObject = await $fetch<User>(url, {
        method: 'PUT'
      })

      SET_LOGGED_IN_USER(loggedInUserObject)
      SET_WAS_BOOTED_OUT(false)
      SET_WAS_ANONYMOUS(false)

      await localforage.setItem(`guest_email${getLocalStorageSuffix.value}`, loggedInUserObject.email)
      
      await localforage.setItem(`guest_password${getLocalStorageSuffix.value}`, loggedInUserObject.password)
      
      await localforage.setItem(`user_level${getLocalStorageSuffix.value}`, loggedInUserObject.level)
      getUserLevel.value = loggedInUserObject.level

      const { path, params, name } = route
      const stringRoute = JSON.stringify({ path, params, name })
      SET_LAST_ROUTE(stringRoute)
    } catch (err) {
      console.error(err)
    }
  }
  async function storeAndCompareFingerprintWithCookie () {
    userFingerprint.value = `${await getCurrentBrowserFingerPrint()}`
    const cookieFingerprint = getCookie('fingerprint')
    if (cookieFingerprint !== userFingerprint.value) {
      const fingerprintMismatchMsg = `Fingerprint mismatch - ${cookieFingerprint} != ${userFingerprint.value}`
      console.warn(fingerprintMismatchMsg)
      Sentry?.captureMessage(fingerprintMismatchMsg);
      setCookie('fingerprint', userFingerprint.value, 365)
    } else {
      console.log('%cFingerprint matches cookie', 'color:green')
    }
  }
  async function signIn(un: string, pw?: string, silent?: boolean) {
    if (!pw) {
      pw = generateId(160)
    }

    SET_USERNAME(un)

    await fetchAuthToken(un, pw)
  }
  async function fetchAuthToken(un: string, pw: string) {
    const url = `${rest.getAPI('login', 'payments')}`
    let branch = ''
    if (rest.isLocalhost()) {
      branch = 'develop.customer.converso.io/localhost'
    } else if (rest.isDevelop()) {
      branch = 'develop.customer.converso.io'
    } else if (rest.isStaging()) {
      branch = 'staging.customer.converso.io'
    } else  {
      branch = 'customer.converso.io'
    }
    const device = `https://${branch}/chat/`
    try {
      const user = await $fetch<User>(url, {
        method: 'PUT',
        body: {
          username: un,
          password: pw,
          businessCode: getBusinessCode.value,
          device // document.location.href
        }
      })

      await handleLoginResponse(user)
    } catch (err) {
      console.error(err)
    }
  }

  function showSigninErrorMsg(source: string) {
    alert(`Incorrect username or password - ${source}`)
  }

  async function handleLoginResponse(loggedInUserObject: User) {
    console.log('handleLoginResponse: ', loggedInUserObject)
    if (!loggedInUserObject) {
      showSigninErrorMsg('handleLoginResponse: No loggedInUserObject!')
      return
    }

    await localforage.setItem(`user_level${getLocalStorageSuffix.value}`, loggedInUserObject.level)
    getUserLevel.value = loggedInUserObject.level

    if (loggedInUserObject.level > 1) {
      await logout(true)
      SET_LOGGED_IN_USER(loggedInUserObject)
      createSharedWorker()
      SET_AUTH_TOKEN(loggedInUserObject.token)
      SET_BUSINESS_CODE(loggedInUserObject.businessCode)
      SET_WAS_BOOTED_OUT(false)

      // remove the guest creds, they are no longer
      await localforage.removeItem(`guest_email${getLocalStorageSuffix.value}`)
      await localforage.removeItem(`guest_password${getLocalStorageSuffix.value}`)
      fetchInvoices()
      SET_WAS_ANONYMOUS(false)
    } else {
      SET_BUSINESS_CODE(loggedInUserObject.businessCode)
      SET_WAS_BOOTED_OUT(false)
      SET_AUTH_TOKEN(loggedInUserObject.token)
      SET_LOGGED_IN_USER(loggedInUserObject)
      createSharedWorker()
      SET_WAS_ANONYMOUS(true)
    }

    signinComplete()
  }

  async function signinComplete() {
    if (!getBusiness.value) {
      await fetchBusiness()
    }

    if (getUserLevel.value > 1) {
      await fetchInvoice()
      // $router?.push('invoices')
    }

    SET_USERNAME('')
  }

  /* SETTERS */
  function SET_WAS_ANONYMOUS(anon: boolean) {
    wasAnonymous.value = anon
  }
  function SET_WAS_BOOTED_OUT(booted: boolean) {
    wasBootedOut.value = booted
  }
  function SET_AUTH_TOKEN(newAuthToken: string | null) {
    authToken.value = newAuthToken
  }
  function SET_INVOICE(newInvoice: Invoice) {
    invoice.value = newInvoice
  }
  function SET_INVOICES(newInvoices: Invoice[]) {
    invoices.value = newInvoices
  }
  function SET_CONVO_LAST_READ(args: {
    conversationId: number,
    lastReadTime: number
  }) {
    const dupe = { ...conversationLastReadTimes.value }
    dupe[args.conversationId] = args.lastReadTime
    conversationLastReadTimes.value = dupe
  }
  function STORE_WIDGET_SETTINGS(settings: {[key: string]: string | number}) {
    widgetSettings.value = settings
  }
  function STORE_DEFAULT_SETTINGS(settings: {[key: string]: string | number}) {
    defaultSettings.value = settings
  }
  function SET_SHOW_SIGNIN(val: boolean) {
    showSignin.value = val
  }
  function SET_IS_ONLINE(val: boolean) {
    isOnline.value = val
  }
  function ADD_USER(user: User) {
    if (!users.value) {
      users.value = []
    }
    if (!user) {
      return
    }
    const existingUser = users.value.find(usr => usr.id === user.id)
    if (existingUser) {
      const usersDupe = [...users.value]
      usersDupe[usersDupe.indexOf(existingUser)] = user
      users.value = usersDupe
    } else {
      users.value = [...users.value, user]
    }
  }
  function DELETE_USER(userId: number) {
    if (!users || !userId) {
      return
    }

    const existingUser = users.value?.filter(usr => usr.id === userId)[0]
    if (existingUser) {
      const usersDupe = [...users.value || []]
      usersDupe.splice(usersDupe.indexOf(existingUser), 1)
      users.value = usersDupe
    }
  }
  function SET_BUSINESS(newBusiness: Business) {
    business.value = newBusiness
  }
  function SET_BUSINESS_CODE(code: string) {
    businessCode.value = code
  }
  function SET_INVOICE_CODE(code: string) {
    console.log('SET_INVOICE_CODE: ', code)
    invoiceCode.value = code
  }
  async function SET_LOGGED_IN_USER(user: null | User) {
    loggedInUser.value = user
    if (user) {
      await localforage.setItem(`loggedInUser${getLocalStorageSuffix.value}`, JSON.stringify(user))
    } else {
      await localforage.removeItem(`loggedInUser${getLocalStorageSuffix.value}`)
    }
  }
  async function SET_LAST_ROUTE(newLastRoute: string) {
    await localforage.setItem(`lastRoute${getLocalStorageSuffix.value}`, newLastRoute)
    lastRoute.value = newLastRoute
  }
  function SET_USERNAME(newUsername: string) {
    username.value = newUsername
  }
  function SET_IS_FULL_DATA_REFRESH(value: boolean) {
    isFullDataRefresh.value = value
  }
  function ADD_CHAT_MESSAGE(args: {newMessage: Message, sender?: User}) {
    const { newMessage, sender } = args
    const messagesDupe = [...chatMessages.value || []]
    const existingMessage = messagesDupe.find(msg => msg.id === newMessage.id)

    if (!existingMessage) {
      messagesDupe.push(newMessage)

      if (sender?.level === 80) {
        messagesDupe.sort((a, b) => {
          if (a.created > b.created) {
            return 1
          }
          return -1
        })
      }
    } else if (existingMessage.updated < newMessage.updated) {
      const index = messagesDupe.indexOf(existingMessage)
      messagesDupe[index] = newMessage
    }

    chatMessages.value = Object.freeze(messagesDupe)
  }
  function DELETE_CHAT_MESSAGE(newMessage: Message) {
    const messagesDupe = [...chatMessages.value || []]
    const existingMessage = messagesDupe.filter(msg => msg.id === newMessage.id)[0]
    if (existingMessage) {
      const index = messagesDupe.indexOf(existingMessage)
      messagesDupe.splice(index, 1)
    }
    chatMessages.value = messagesDupe
  }
  function SET_CONVERSATION(newConversation: null | Conversation) {
    conversation.value = newConversation
    SET_CONVERSATION_ID(newConversation?.id || null)
  }
  function SET_CONVERSATIONS(newConversations: Conversation[], incrementalSync?: boolean) {
    let updatedConversations: Conversation[]

    if (incrementalSync) {
      updatedConversations = [...conversations.value || []]
      
      newConversations.forEach(newConversation => {
        const existingConversation = updatedConversations.find(existingConversation => {
          return existingConversation.id === newConversation.id;
        })
        existingConversation ? Object.assign(existingConversation, newConversation) : updatedConversations.push(newConversation);
      })
    } else {
      updatedConversations = newConversations
    }

    const sortedConversations = updatedConversations?.filter((convo) => {
      return convo.type < 1000 && convo.active
    }).sort((a, b) => {
      const aTime = a.lastMessage || a.updated
      const bTime = b.lastMessage || b.updated
      if (aTime > bTime) {
        return -1
      }
      if (aTime < bTime) {
        return 1
      }
      return 0
    })
    sortedConversations.forEach(convo => {
      if (!convo.lastMessage) {
        const messages = getChatMessages.value?.filter((msg) => {
          return msg.conversation === convo.id &&
            msg.type !== 200
        }) || []
        if (messages.length > 0) {
          convo.lastMessage = messages[messages.length - 1].updated
        } else {
          convo.lastMessage = convo.updated
        }
      }
    })
    conversations.value = Object.freeze(sortedConversations)
  }
  function UPDATE_CONVERSATION(newConversation: Conversation) {
    if (!conversations.value) {
      conversations.value = Object.freeze([newConversation])
      return
    }

    const oldConvo = conversations.value.find(convo => convo.id === newConversation.id)
    const index = oldConvo ? conversations.value?.indexOf(oldConvo) : -1
    const dupe = conversations.value ? [...conversations.value] : []

    if (index > -1) {
      dupe[index] = newConversation
    } else {
      const tempConvo = conversations.value?.find(convo => convo.id === 0)
      if (tempConvo) {
        const tempIndex = conversations.value?.indexOf(tempConvo)
        dupe[tempIndex] = newConversation
      } else {
        dupe.push(newConversation)
      }
    }
    console.log('UPDATE_CONVERSATION:', dupe)
    conversations.value = Object.freeze(dupe)
  }
  
  function SET_MESSAGES(newChatMessages: null | Message[], incrementalSync: boolean = false) {
    if (!chatMessages.value) {
      chatMessages.value = newChatMessages
      return // can't freeze null
    }

    let newMessages: Message[]

    if (incrementalSync) {
      newMessages = [...chatMessages.value || []]
      
      newChatMessages?.forEach(newMessage => {
        const existingMessage = newMessages.find(existingMessage => {
          return existingMessage.id === newMessage.id;
        })
        existingMessage ? Object.assign(existingMessage, newMessage) : newMessages.push(newMessage);
      })
    } else {
      newMessages = newChatMessages || []
    }

    newMessages = newMessages?.filter(msg => msg.type === 0)
      .sort((a, b) => {
        if (a.created > b.created) {
          return 1
        }
        if (a.created < b.created) {
          return -1
        }
        return 0
      }) || []

    chatMessages.value = Object.freeze(newMessages)
  }
  async function DELETE_CONVERSATION(newConversation: Conversation) {
    if (!conversations.value) {
      return
    }
    
    const oldConvo = conversations.value?.filter(convo => convo.id === newConversation.id)[0]
    const dupe = [...conversations.value || []]

    if (oldConvo) {
      const index = conversations.value?.indexOf(oldConvo)

      delete dupe[index]
      console.log('DELETE_CONVERSATION:', dupe)

      SET_CONVERSATIONS(dupe)

      if (conversationId.value === oldConvo.id) {
        goToConversationList()
      }
    }
  }
  function goToConversationList() {
    SET_CONVERSATION(null)
    // go to the conversations screen
    router.replace(`/widget/conversations/${route.params.businessCode}/${route.params.group}`)
  }
  function setConversation(conversation: Conversation) {
    router.push(`/widget/chat/${route.params.businessCode}/${route.params.group}`)
    SET_CONVERSATION(conversation)
    SET_CONVO_LAST_READ({
      conversationId: conversation.id,
      lastReadTime: Date.now()
    })
  }
  function SET_GROUP(newGroup: number) {
    group.value = newGroup
  }
  async function SET_CONVERSATION_ID(newConversationId: null | number) {
    conversationId.value = newConversationId
    await localforage.setItem(`conversationId${getLocalStorageSuffix.value}`, newConversationId)
  }
  function SET_CHAT_UI_STATE(newVal: number) {
    chatUIState.value = newVal
  }
  function SET_IS_TYPING(newIsTyping: boolean) {
    isTyping.value = newIsTyping
  }
  function SET_WEBSOCKET_CLOSE_TO() {
    closedEventTO.value = setTimeout(() => {
      // no closed event recieved - recover
      console.warn('Websocket No CLOSE event receuived: CLOSE WORKER PORT!')
      webSocketWorker?.close();
      webSocketWorker?.terminate();
      webSocketWorker = null;
      sendTokenEventTO.value = null;
      checkAuthToken();
    }, recoveryTimeoutDuration)
  }

  /*** GETTERS */
  const getAuthToken = computed(() => authToken.value)
  const getWasAnonymous = computed(() => wasAnonymous.value)
  const getWasBootedOut = computed(() => wasBootedOut.value)
  const getInvoice = computed(() => invoice.value)
  const getInvoices = computed(() => invoices.value)
  const getIsTyping = computed(() => isTyping.value)

  const getShowTypingTO = computed(() => showTypingTO.value)
  const getHideTypingTO = computed(() => hideTypingTO.value)
  const getMessageSentConfirmationTO = computed(() => messageSentConfirmationTO.value)
  const getBusiness = computed(() => business.value)
  const getBusinessCode = computed(() => businessCode.value)
  
  const getInvoiceCode = computed(() => invoiceCode.value)
  const getLoggedInUser = computed(() => loggedInUser.value)
  const getUsername = computed(() => username.value)
  const getShowSignin = computed(() => showSignin.value)
  const isLoggedIn = computed(() => loggedInUser.value && loggedInUser.value.name !== 'Anonymous')
  const getConversations = computed(() => conversations.value)
  const getConversationId = computed(() => conversationId.value)
  const getChatMessages = computed(() => chatMessages.value)
  const getUsers = computed(() => {
    const allUsers = users.value ? [...users.value] : []
    if (loggedInUser.value) {
      allUsers.push(loggedInUser.value)
    }
    return allUsers
  })
  const getConvoLastReadTimes = computed(() => conversationLastReadTimes.value)
  const getWidgetSettings = computed(() => widgetSettings.value)
  const getLastRoute = computed(() => lastRoute.value)
  const getIsOnline = computed(() => isOnline.value)
  const getUserLevel = ref(0)
  localforage.getItem<string>(`user_level${getLocalStorageSuffix.value}`).then(userLevel => {
    if (userLevel) {
      getUserLevel.value = parseInt(userLevel)
    }
  })

  return {
    // STATE
    showSignin,
    authToken,
    isTyping,
    chatMessages,
    users,
    wasBootedOut,
    conversations,
    chatUIState,
    killSharedWorker,
    conversation,
    conversationId,
    group,
    invoice,
    invoices,
    business,
    conversationLastReadTimes,
    businessCode,
    invoiceCode,
    loggedInUser,
    username,
    wasAnonymous,
    widgetSettings,
    isFullDataRefresh,
    lastRoute,

    // ACTIONS
    getRouteParams,
    fetchInvoice,
    fetchBusiness,
    fetchUsers,
    fetchInvoices,
    fetchConversations,
    logout,
    removeLoginData,
    fetchMessages,
    selfService,
    getBankIntent,
    getCrezcoLink,
    getCrezcoBanks,
    beaconTimeoutHandler,
    throttledCheckDataFreshness,
    updateImageFile,
    putFile,
    putResource,
    constructNewConversation,
    makeNewConversation,
    createNewConversation,
    updateConversation,
    getStoredConversationId,
    anonymousLogin,
    requestLogin,
    checkAuthToken,
    sendMessage,
    upgradeVisitorToGuest,
    signIn,
    storeAndCompareFingerprintWithCookie,
    fetchAuthToken,
    showSigninErrorMsg,
    handleLoginResponse,
    signinComplete,
    getFileURL,
    SET_WAS_ANONYMOUS,
    SET_WAS_BOOTED_OUT,
    SET_AUTH_TOKEN,
    SET_INVOICE,
    SET_INVOICES,
    SET_CONVO_LAST_READ,
    STORE_WIDGET_SETTINGS,
    STORE_DEFAULT_SETTINGS,
    SET_SHOW_SIGNIN,
    SET_IS_ONLINE,
    ADD_USER,
    DELETE_USER,
    SET_BUSINESS,
    SET_BUSINESS_CODE,
    SET_INVOICE_CODE,
    SET_LOGGED_IN_USER,
    SET_LAST_ROUTE,
    SET_USERNAME,
    SET_IS_FULL_DATA_REFRESH,
    ADD_CHAT_MESSAGE,
    DELETE_CHAT_MESSAGE,
    SET_CONVERSATION,
    SET_CONVERSATION_ID,
    SET_CONVERSATIONS,
    UPDATE_CONVERSATION,
    SET_MESSAGES,
    DELETE_CONVERSATION,
    SET_GROUP,
    SET_CHAT_UI_STATE,
    SET_IS_TYPING,
    SET_WEBSOCKET_CLOSE_TO,

    // GETTERS
    getAuthToken,
    getWasAnonymous,
    getWasBootedOut,
    getInvoice,
    getInvoices,
    getIsTyping,
    getBusiness,
    getBusinessCode,
    getLocalStorageSuffix,
    getInvoiceCode,
    getLoggedInUser,
    getUsername,
    getShowSignin,
    getIsOnline,
    isLoggedIn,
    getConversations,
    getConversationId,
    getChatMessages,
    getUsers,
    getConvoLastReadTimes,
    getWidgetSettings,
    getLastRoute,
    getUserLevel,
    getShowTypingTO,
    getHideTypingTO,
    getMessageSentConfirmationTO,
  }
})