function checkIfOutdatedEntity (oldEntity, newEntity) {
  const isOutdated = oldEntity?.updated > newEntity?.updated

  if (isOutdated) {
    console.warn('** ASYNC ** This message is out of order - a newer entity already exists!', {
      oldEntity,
      newEntity
    })
  }

  return isOutdated
}

export const handleMessage = async (msg) => {
  const payload = msg.data || msg
  if (!payload) {
    console.warn('missing payload - can not handle message!', msg)
    return
  }
  
  const store = usePortalStore()
  const splitMessage = payload.split('=')

  console.log('splitMessage: ', splitMessage)
  if (splitMessage[0].includes(':')) {
    const contextAndId = splitMessage[0].split(':')
    const businessCodeAndUID = contextAndId[0].split('.')
    const businessCode = businessCodeAndUID[0]
    
    const userId = parseInt(businessCodeAndUID[1])

    if (
      !(
        businessCode === store.getBusiness.businessCode &&
        userId === store.getLoggedInUser.id
      )
    ) {
      console.warn('** AUTH ** This message is not meant for this user - IGNORE')
      return
    }

    const context = contextAndId[1]
    const action = contextAndId[2]
    const id = parseInt(contextAndId[3])
    const conversationId = parseInt(contextAndId[4])
    const groupId = parseInt(contextAndId[5])

    if (businessCode !== store.getBusinessCode) {
      console.warn('ignoring FB message for other business:', businessCode)
      return
    }

    console.log('contextAndId:', contextAndId)
    console.log('businessCode:', businessCode)
    console.log('context:', context)
    console.log('action:', action)
    console.log('id:', id)
    console.log('conversationId:', conversationId)
    console.log('groupId:', groupId)

    switch (context) {
      case 'BUSINESS':
        switch (action) {
          case 'DELETE':
            // ignore this - we will not delete the business
            break

          case 'UPDATE':
            const business = store.getBusiness
            const businessEntity = JSON.parse(splitMessage[1])

            console.log({ businessEntity })

            if (checkIfOutdatedEntity(business, businessEntity)) {
              return
            }

            // pull the whole business entity here
            store.fetchBusiness().catch(err => console.error(err))
            break

          default:
            store.fetchBusiness().catch(err => console.error(err))
        }
        break

      case 'CONVERSATION':
        // eslint-disable-next-line no-case-declarations
        const oldConversation = store.getConversations?.filter(conv => conv.id === id)[0]

        switch (action) {
          case 'DELETE':
            if (oldConversation) {
              // delete the conversation
              store.DELETE_CONVERSATION(oldConversation)
            }
            break

          case 'CREATE':
          case 'UPDATE':
            // eslint-disable-next-line no-case-declarations
            const conversationEntity = JSON.parse(splitMessage[1])

            if (checkIfOutdatedEntity(oldConversation, conversationEntity)) {
              return
            }

            const convoMessages = store.getChatMessages?.filter(msg => msg.conversation === conversationEntity.id)
            conversationEntity.lastMessage = convoMessages?.length ? convoMessages[convoMessages.length - 1].updated : conversationEntity.updated

            store.UPDATE_CONVERSATION(conversationEntity)
            break
        }
        break

      case 'MESSAGE':
        // eslint-disable-next-line no-case-declarations
        const oldMessage = store.getChatMessages?.filter(msg => msg.id === id)[0]
        switch (action) {
          case 'DELETE':
            if (oldMessage) {
              store.DELETE_CHAT_MESSAGE(oldMessage.id)
            }
            break

          case 'CREATE':
          case 'UPDATE':
            // eslint-disable-next-line no-case-declarations
            const messageEntity = JSON.parse(splitMessage[1])
            let sender = store.getUsers?.find(user => user.id === messageEntity.sender)
            console.log('messageEntity: ', messageEntity)
            console.log('sender: ', sender)

            switch (action) {
              case 'CREATE':
                if (messageEntity.sender !== store.loggedInUser.id) {
                  if (!sender) {
                    await store.fetchUsers()
                    sender = store.getUsers?.find(user => user.id === messageEntity.sender)
                  }

                  clearTimeout(store.getShowTypingTO)
                  clearTimeout(store.getHideTypingTO)
                  store.SET_IS_TYPING(false)

                  if (sender && sender.level === 80) {
                    setTimeout(() => {
                      clearTimeout(store.getShowTypingTO)
                      clearTimeout(store.getHideTypingTO)
                      store.SET_IS_TYPING(false)
                    }, 500)
                  }
                }

                if (store.getMessageSentConfirmationTO.text === messageEntity.text) {
                  console.warn('message confirmation recieved from WS - clearing re-auth timeout')
                  store.getMessageSentConfirmationTO.text = null
                  clearTimeout(store.getMessageSentConfirmationTO.to)
                }
              break
            } 

            if (checkIfOutdatedEntity(oldMessage, messageEntity)) {
              return
            }

            const conversation = store.conversations.find(convo => convo.id === messageEntity.conversation)

            const dupeConvo = { ...conversation }
            dupeConvo.lastMessage = messageEntity.updated
            store.UPDATE_CONVERSATION(dupeConvo)
            store.ADD_CHAT_MESSAGE({newMessage: messageEntity, sender})
            break
        }
        break

      case 'USER':
        const oldUser = store.getUsers?.find(user => user.id === id)
        if (action === 'DELETE') {
          if (!oldUser) {
            return
          }
          store.DELETE_USER(id)
        } else {
          const userEntity = JSON.parse(splitMessage[1])
          console.log('USER: ', action, userEntity)

          if (checkIfOutdatedEntity(oldUser, userEntity)) {
            return
          }

          store.ADD_USER(userEntity)
        }

        break

      case 'TASK':
        switch (action) {
          case 'LOGOUT':
            store.SET_WEBSOCKET_CLOSE_TO()
            break
        }

      // handle file update events
      // the whole file payload will be sent, including linkedType and linkedId
      // this will refer to the user or business which the file relates to
      // set the correcponding entity's updated timecode to be the same as the updated prop
      // from the message payload, and update the entity in vuex store
      // this will force the image to reload
      case 'FILE':
        switch (action) {
          case 'UPDATE':
            const fileEntity = JSON.parse(splitMessage[1])
            switch (fileEntity.linkedType) {
              case 'USER':
                const linkedUser = store.getUsers?.filter(user => user.id === fileEntity.linkedId)[0]
                if (linkedUser) {
                  const dupeUser = {...linkedUser}
                  dupeUser.updated = fileEntity.updated
                  if (dupeUser.picture !== fileEntity.id) {
                    dupeUser.picture = fileEntity.id
                  }
                  if (dupeUser.picture) {
                    await store.getFileURL(dupeUser.picture)
                      .then(url => {
                        dupeUser.pictureBase64 = url
                        store.ADD_USER(dupeUser)
                      })
                      .catch(err => console.error(err))
                  } else {
                    store.ADD_USER(dupeUser)
                  }
                }
                break

              case 'BUSINESS':
                if (fileEntity.linkedId === store.getBusiness?.id) {
                  store.fetchBusiness().catch(err => console.error(err))
                }
                break
            }
            break
        }
        break
    }
  }
}
